(function($, undefined) {

    $.nette.ext('scrollTo',{
        success: function (payload, status, jqXHR, settings) {
            if (!settings.nette) {
                return;
            }
            var target = settings.nette.el.data('scroll-to');
            if (target) {
                if (target == "top") {
                    goTop();
                } else {
                    goTopOfElement(target);
                }
            }
        }
    });

})(jQuery);
